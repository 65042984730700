<template>
  <app-page 
    :head="data.head" 
    :body="data.body" 
  />
</template>

<script setup>
const nuxtApp = useNuxtApp();
const { params, query } = useRoute();
// const { data } = await useFetch(
//   `/api/page_index?lng=${params.locale}&market=${nuxtApp.$market}`
// );

// !!! CAN'T USE useFetch() HERE, there's a bug coming back from support section !!!
const preview = query.preview ? `&preview=${query.preview}` : "";
const data = await $fetch(
  `/api/page_index?lng=${params.locale}&market=${nuxtApp.$market}${preview}`
);
</script>
